import { render, staticRenderFns } from "./ProductWizard.vue?vue&type=template&id=21a6f172&scoped=true"
import script from "./ProductWizard.vue?vue&type=script&lang=js"
export * from "./ProductWizard.vue?vue&type=script&lang=js"
import style0 from "./ProductWizard.vue?vue&type=style&index=0&id=21a6f172&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a6f172",
  null
  
)

export default component.exports